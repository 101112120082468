<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.37 8.87988H17.62" stroke="url(#paint0_linear_7_1339)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.38 8.87988L7.13 9.62988L9.38 7.37988" stroke="url(#paint1_linear_7_1339)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.37 15.8799H17.62" stroke="url(#paint2_linear_7_1339)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.38 15.8799L7.13 16.6299L9.38 14.3799" stroke="url(#paint3_linear_7_1339)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="url(#paint4_linear_7_1339)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_7_1339" x1="17.6239" y1="8.87988" x2="15.1462" y2="12.1697" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9C9C"/>
        <stop offset="1" stop-color="#F87171"/>
        </linearGradient>
        <linearGradient id="paint1_linear_7_1339" x1="9.38222" y1="7.37988" x2="5.86974" y2="8.56435" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9C9C"/>
        <stop offset="1" stop-color="#F87171"/>
        </linearGradient>
        <linearGradient id="paint2_linear_7_1339" x1="17.6239" y1="15.8799" x2="15.1462" y2="19.1697" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9C9C"/>
        <stop offset="1" stop-color="#F87171"/>
        </linearGradient>
        <linearGradient id="paint3_linear_7_1339" x1="9.38222" y1="14.3799" x2="5.86974" y2="15.5643" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9C9C"/>
        <stop offset="1" stop-color="#F87171"/>
        </linearGradient>
        <linearGradient id="paint4_linear_7_1339" x1="22.0148" y1="2" x2="-2.49668" y2="8.19924" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE9C9C"/>
        <stop offset="1" stop-color="#F87171"/>
        </linearGradient>
        </defs>
    </svg>
</template>